body {
    padding-top: 20px;
    font-family: system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    color: #ffffff;
    background: #181818;
    margin: 0 auto;
}

.App {
    background: #181818;
    text-align: unset;
}

.faq .section1 {
    max-width: 700px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
}

.faq h1,.faq h2,.faq h4,.faq .rounded {
    font-family: ui-rounded, "SF Pro Rounded", system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
}

.faq h1 {
    text-align: center;
    font-weight: 900;
}

.faq h2 {
    text-align: left;
    font-family: ui-rounded, "SF Pro Rounded", system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-weight: 900;
}
.faq h4 {
    font-weight: 700;
    margin-bottom: 0.45em;
    margin-top: 2em;
}
.faq ul {
    list-style: none;
}
.faq p, .faq li {
    color: rgba(255,255,255,0.8);
    line-height: 1.58;
    margin-top: 0;
}

.faq a {
    text-decoration: none;
    border-bottom: 1px dotted;
    color: white;
}
.faq .rounded {
    font-weight: 700;
}

.faq .section2 {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff;
    color: black;
    max-width: 700px;
    margin: 0 auto;
}
.faq p.section2, .faq li.section2 {
    color: rgba(74, 74, 74, 1);
    padding: 0px;
}

.faq ul li:before {
    content: "\2665";
    color: #FF6133;
    color: color(display-p3 1 0.38 0.2);
    font-size: 0.7em;
    left: -2px;
    bottom: 2px;
    position: relative;
}

.faq ul.white-bullet li:before {
    content: "\2665";
    font-size: 0.7em;
    color: rgba(255,255,255,0.8);
    left: -2px;
    bottom: 2px;
    position: relative;
}

.faq .directions-button {
    font-variant: small-caps;
    border: 1px solid;
    padding: 0px 7px 1px 7px;
    border-radius: 13px;
    font-weight: bold;
    white-space: nowrap;
}

.legal{
    display: flex;
}